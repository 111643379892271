import PropTypes from 'prop-types';
import headerThemes from 'config/branding/header-themes';
import Link from '@activebrands/core-web/components/Link';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import LogotypeIcon from 'components/icons/LogotypeIcon';

const TopLogotypeMobile = ({ $style = {}, hasBanner = false, theme = 'default' }) => {
    const [css] = useStyletron();

    return (
        <Link
            className={css({
                color: 'var(--color-text-header)',
                display: ['block', null, null, null, 'none'],
                left: '50%',
                position: 'absolute',
                top: hasBanner ? 'calc(var(--height-banner) + 12px)' : '12px',
                transform: 'translate3d(-50%, 0, 0)',
                zIndex: 'var(--zindex-top-logo)',
                ...$style,
            })}
            // @todo: Add correct title
            title="Grebcommerce"
            to="/"
        >
            <LogotypeIcon $style={headerThemes[theme]} color="var(--color-top-logo-mobile)" />
        </Link>
    );
};

TopLogotypeMobile.propTypes = {
    $style: PropTypes.object,
    hasBanner: PropTypes.bool,
    theme: PropTypes.string.isRequired,
};

export default TopLogotypeMobile;
