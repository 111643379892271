import headerHeights from 'config/branding/header-heights';
import useDynamicMainContentTop from '@activebrands/core-web/hooks/useDynamicMainContentTop';
import { styled } from '@activebrands/core-web/libs/styletron';
import { LayoutProps } from '@activebrands/core-web/types/layout';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Banner from 'components/Header/Banner';
import TopLogotypeMobile from 'components/Header/TopLogotypeMobile';
import { getHeaderTheme } from 'components/Header/getHeaderTheme';

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
});

const Main = styled('main', {
    flex: '1 0 auto',
});

const DefaultLayout = ({ children, page }: LayoutProps) => {
    const alternateHrefs = page.data?.hrefs || [];
    const transparentHeader = page?.data?.content?.transparentHeader;
    const pageContent = page?.data?.content || {};
    const theme = getHeaderTheme({
        heroTextColor: pageContent.heroContent?.[0]?.textColor,
        transparent: transparentHeader,
    });
    const banner = pageContent.banner?.[0] || {};
    const bannerTexts = banner?.texts?.map(text => text?.richText || text);
    const hasBanner = bannerTexts?.length > 0;
    const { bannerHeight, headerHeight, topLogoMobileHeight } = headerHeights;
    const spaceAroundTopLogoMobile = 24;
    const dynamicBannerHeight = hasBanner ? bannerHeight : 0;

    // Here we add 'noPageType' to 404 page if page.type is undefined
    const pageType = page.type || 'noPageType';

    // All themes on template "page" let content of the page fall behind the header
    const contentTopOfPage = ['page', 'noPageType'].includes(pageType);

    // Calculate the distance from the top of the page for content
    const mobileContentNotTopOfPage = topLogoMobileHeight + spaceAroundTopLogoMobile + dynamicBannerHeight;
    const mobileMainContentTop = `${contentTopOfPage ? 0 : mobileContentNotTopOfPage}px`;
    const desktopMainContentTop = `${contentTopOfPage ? 0 : headerHeight + dynamicBannerHeight}px`;

    // Update the distance from the top of the page for content
    useDynamicMainContentTop({ desktopMainContentTop, mobileMainContentTop });

    return (
        <Wrapper $style={{ marginTop: [mobileMainContentTop, null, null, null, null, desktopMainContentTop] }}>
            <Header alternateHrefs={alternateHrefs} theme={theme} />
            {hasBanner && (
                <Banner spin={banner?.spin} texts={bannerTexts} theme={banner?.theme} to={banner?.link?.url} />
            )}
            <TopLogotypeMobile hasBanner={hasBanner} theme={theme} />
            <Main>{children}</Main>
            <Footer alternateHrefs={alternateHrefs} />
        </Wrapper>
    );
};

export default DefaultLayout;
